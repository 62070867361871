import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const defaultUser = () => ({
	roles: null,
	login: null,
	token: null,
});

export default new Vuex.Store({
    state: {
      formData: {
        menuItem: 0,
        markupType: 1
      },
      user: null,
      signedIn: false,
      redirectPathParams: null,
      login: null,
    },

    mutations: 
    {
      formUpdate(state, payload) {
        state.formData = {
            menuItem: payload.menuItem,
            markupType: payload.markupType
        }
      },

      loginUserMutation(state, user){
        state.user = defaultUser();
        Object.assign(state.user, user);
        state.signedIn = true;
        state.login = user.login;

        localStorage.nstoken = user.token;
        axios.defaults.headers.common["Authorization"]= "Bearer " + user.token;
      },

      logoffUserMutation(state){
        state.user = null;
        state.signedIn = false;
        state.login = null;
        axios.defaults.headers.common["Authorization"]= "";
        localStorage.nstoken = null;
      },

      redirectPathMutation(state, pathParams){
        //alert('redirectPathMutation');
        state.redirectPathParams = pathParams;
      },
    },

    actions:{
      async checkToken({ commit }, checkUrl)
      {
        if (localStorage.nstoken) {
          axios.defaults.headers.common["Authorization"]= "Bearer " + localStorage.nstoken;
        }

        try
        {
          var result = await axios.get(checkUrl);
          if (result.status === 200) 
          {
            commit('loginUserMutation', result.data);
            return;
          }

          commit('logoffUserMutation', defaultUser());
        }
        catch
        {
          commit('logoffUserMutation', defaultUser());
        }
      },

      async logoff({commit}, logoffUrl)
      {
          await axios.delete(logoffUrl);
        commit('logoffUserMutation', defaultUser());
      },

      resolveSuccessLogin({ commit }, userInfo){
        commit('loginUserMutation', userInfo);
        if (this.state.redirectPathParams == null)
        {
            return { name: 'default' }
        }
        else
        {
          if (this.state.redirectPathParams.name == 'login')
          {
            return {name: 'default'}
          }
          return this.state.redirectPathParams;
        }
      },
    },
  });