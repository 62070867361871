<template>
  <div id="app">
    <MasterComponent :markupType="this.markupType"/>
  </div>
</template>

<script>
import MasterComponent from './components/MasterComponent.vue'
import { MarkupTypeEnum } from './settings/MarkupTypeEnum.ts'

export default {
  name: 'App',
  components: {
    MasterComponent
  },
   data(){
    return {
      markupType : Number
    }
  },

   methods: {
    setActiveMarkUp : function()
    {
      var m = this.$store.state.formData.markupType;
      switch (m) {
        case MarkupTypeEnum.Main:
          this.markupType = 1;
          break;
        case MarkupTypeEnum.Free:
          this.markupType = 2;
          break;
        default: return 1;
      }
    },
   },

    created(){
      this.markupType = 1;
      this.$store.subscribe((mutation) => {
      if (mutation.type === 'formUpdate') {
        this.setActiveMarkUp();
      }});

      document.title = "НАСТОЯТЕЛЬ 2.1";
    }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  height: 100%;
}

html{
  height: 100%;
}

#app {
  color: #2c3e50;
  margin: 0px;
  font-family: Tahoma, sans-serif; 
  font-size: 12px;
  height: 100%;
}

h1 {
    font-size: 18px;
    font-weight: bold;
}

h2{
  font-size: 14px;
  font-weight: bold;
  color: #000099;
}

input{
  font-size: 12px
}

textarea
{
   font-size: 12px;
}

select{
 font-size: 12px  
}

button {
  cursor: pointer;
}


#app
{
  width: 100%;
}
.likebr {padding-top: 10px;}
.bluetext {font-size: 12px;  font-weight: bold; color:#0066ff}
.greentext{font-size: 12px;  font-weight: bold;  color :  #33cc00;}
.smalltext{font-size: 10px;}
.redtext{font-size: 12px; font-weight: bold; color: #ff0000;}
.bigheader{color:#000099; font-weight: bold;}

.loading{font-size: 12px; font-weight: bold; color:#2c3e50; padding-top: 20px; padding-bottom: 10px;}

.child{font-size: 13px;}
.child td{
    border: 1px solid;
    border-bottom-color: #0066ff;
    border-top-color: #0066ff;
    border-right-color: #0066ff;
    border-left-color: #0066ff;
}

.bluehead {
    font-size: 12px;
    font-weight: bold;
    color: #000099;
}

.boldtext {
    font-weight: bold;
    font-size: 10px;
    color: #222222;
}

.btn {
  font-size: 16px;
  font-weight: bold;
  width:100px;
  height:45px;
  color:#000099;
  margin: 10px;
}

.fieldset{
  margin-top: 10px;
  padding-top: 5px;
  border: 1px solid;
  border-bottom-color: #0066ff;
  border-top-color: #0066ff;
  border-right-color: #0066ff;
  border-left-color: #0066ff;
  height: 350px; 
  margin-left: 10px;
  overflow-y: scroll; 
  margin-right: 10px; 
  margin-bottom: 5px;
}

.vailderror{border:2px solid red !important; background-color:#f1888841 !important}

.ok{
  width: 150px;
  font-size: 13px;
  font-weight: bold;
  color: #21c478;
  border: 1px solid #21c478;
  background-color: #c0f0dc;
  margin-top: 15px;
  padding: 5px;
}
::-webkit-file-upload-button{cursor: pointer;}

.tablediv{padding-left:15px;}

.imgpointer{cursor: pointer;}
.bigtext{font-size: 15px;}

.menu {
  font-size:13px;
  color:#27274e;
}

.menuactive {
  font-size: 14px;
  color: Red;
  font-weight: bold;
}

.addpicdiv{
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: 1px solid;
  border-bottom-color: #0066ff;
  border-top-color: #0066ff;
  border-right-color: #0066ff;
  border-left-color: #0066ff; 
  margin-left: 10px; 
  margin-right: 10px; 
  cursor: pointer;
}

.addpicdiv input{
  cursor: pointer;
}

.addpicdiv div{
   margin-top: 5px;  
}

label {cursor: pointer;}

input[type=radio]{cursor: pointer;}

</style>
