import Vue from 'vue'
import VueRouter from 'vue-router'
import EmptyComponent from '../components/EmptyComponent.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'default',
    props: {menuItem: -1},
    component: EmptyComponent,
    meta: { access: ["Admin"] },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "ns" */ '../components/Inside/Login.vue'),
    props: {},
    meta: { access: ["*"] }
  },

  {
    path: '/hash',
    name: 'hash',
    component: () => import(/* webpackChunkName: "ns" */ '../components/Inside/Hash.vue'),
    props: {},
    meta: { access: ["*"] }
  },

  {
    path: '/denied',
    name: 'denied',
    component: () => import(/* webpackChunkName: "ns" */ '../components/Inside/Denied.vue'),
    props: {},
    meta: { access: ["*"] }
  },

  {
    path: '/events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //props: route => ({ pageNumber: parseInt(route.params.pageNumber), pageSize: 20})
    component: () => import(/* webpackChunkName: "ns" */ '../components/News/NewsEntryPoint.vue'),
    props: {pageSize: 20, menuItem: 4},
    meta: { access: ["Admin"] },

    children:[
      {
        path: '', 
        name: 'eventslist',
        component: () => import(/* webpackChunkName: "ns" */ '../components/News/NewsList.vue'),
        props: {pageSize: 20},
        meta: { access: ["Admin"] },  
      },
      {
      path: 'edit/anons/:id',
      name: 'editeventanons',
      component:() => import(/* webpackChunkName: "ns" */ '../components/News/NewsAnons.vue'),
      props: route => ({ uid: route.params.id }),
      meta: { access: ["Admin"] },
      },
      {
        path: 'edit/full/:id',
        name: 'editeventfull',
        component:() => import(/* webpackChunkName: "ns" */ '../components/News/NewsFull.vue'),
        props: route => ({ uid: route.params.id }),
        meta: { access: ["Admin"] },
      },
      {
        path: 'edit/eventdates/:id',
        name: 'editeventdates',
        component:() => import(/* webpackChunkName: "ns" */ '../components/News/NewsDates.vue'),
        props: route => ({ uid: route.params.id }),
        meta: { access: ["Admin"] },
      },
      {
        path: 'delete/:id',
        name: 'eventdelete',
        component:() => import(/* webpackChunkName: "ns" */ '../components/News/NewsDelete.vue'),
        props: route => ({ uid: route.params.id }),
        meta: { access: ["Admin"] },
      }         
    ]
  },
  {
    path: '/prihods',
    name: 'prihods',
    component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodsEntryPoint.vue'),
    props: {menuItem: 1},
    meta: { access: ["Admin"] },

    children:[
      {
        path: '', 
        name: 'prihodslist',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodsList.vue'),
        props: {},
        meta: { access: ["Admin"] }, 
      },
      {
        path: ':id/info', 
        name: 'editprihodinfo',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodInfo.vue'),
        props: route => ({ id : parseInt(route.params.id) }),
        meta: { access: ["Admin"] },
      },
      {
        path: ':id/full', 
        name: 'editprihodfull',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodFull.vue'),
        props: route => ({ id : parseInt(route.params.id) }),
        meta: { access: ["Admin"] },
      },
      {
        path: ':id/priests', 
        name: 'editprihodpriests',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodPriests.vue'),
        props: route => ({ id : parseInt(route.params.id) }),
        meta: { access: ["Admin"] },
      },
      {
        path: ':id/timetable', 
        name: 'editprihodtimetable',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodTimeTable.vue'),
        props: route => ({ id : parseInt(route.params.id) }),
        meta: { access: ["Admin"] },
      }, 
      {
        path: ':id/albums', 
        name: 'editprihodalbums',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodAlbumsList.vue'),
        props: route => ({ id : parseInt(route.params.id) }),
        meta: { access: ["Admin"] },
      },  
      {
        path: ':id/albums/:albumId', 
        name: 'editprihodalbum',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodAlbumEdit.vue'),
        props: route => ({ id : parseInt(route.params.id), albumId : parseInt(route.params.albumId)}),
        meta: { access: ["Admin"] },
      }, 
      {
        path: ':id/albums/:albumId/delete', 
        name: 'deleteprihodalbum',
        component: () => import(/* webpackChunkName: "ns" */ '../components/Prihods/PrihodAlbumDelete.vue'),
        props: route => ({ id : parseInt(route.params.id), albumId : parseInt(route.params.albumId)}),
        meta: { access: ["Admin"] },
      }, 
      
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, 
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router