
<template>
<div id="panelUserMenu" style="width:200px">
<span><b>Доступные разделы:</b></span>
<div>
  <div v-if="loading" class="loading">
  Загружаю...
 </div>
  <ul>
    <li v-for="mi in menuItems" v-bind:key="mi.id" class="listitem">
      <router-link :to="{name: selectRoute(mi.id), params:{menuItem: mi.id}}" :class="[{ menuactive: mi.id == selectedItem }, { menu: mi.id != selectedItem }]">{{mi.name}}</router-link>
    </li>
  </ul>
</div>

<div class="lastMenu">
<router-link :to="{name: 'default', params:{menuItem: 0}}" class="menu"> >>Перейти в начало</router-link>
</div>
</div>
</template>

<script>
import { UserMenuEnum } from '../settings/UserMenuEnum.ts'; 

export default {
  name: 'UserMenu',
  data(){
    return {
      loading : Boolean,
      selectedItem: {
        default: -1,
        type: Number
      },
      menuItems: []
    }
  },
  props: {
   currentMenuItem: Number
  },

  methods: {
    selectRoute: function(menuId)
    {
      switch (menuId) {
        case UserMenuEnum.Events:
          return "eventslist";
        case UserMenuEnum.Prihods:
          return "prihodslist";
        default: return "default";
      }
    },

    setActiveMenuItem : function()
    {
      this.selectedItem = this.$store.state.formData.menuItem;
    },

    async getData() {
       try {
        var response = await this.$http.get(this.$restApi +'/sections');
        this.menuItems = response.data;
        this.loading = false;
        this.selectedItem = -1;
      } catch (error) {
        console.log(error);
      }
    }
  },

  created(){
      this.getData();
      this.$store.subscribe((mutation) => {
      if (mutation.type === 'formUpdate') {
        this.setActiveMenuItem();
      }});
    }
}
</script>

<style scoped>
.listitem{
padding-top: 5px;
padding-bottom: 5px;
}


#panelUserMenu{
  padding-top:15px;
}

ul{padding-left:25px;}

li {
margin-top:0px;
margin-left:0px;
margin-bottom:10px;
list-style-image: url("../assets/menu_point.jpg");
list-style-position:outside;
vertical-align:top;
}
</style>