export const UserMenuEnum = {
  Prihods : 1,
  PhotoGallery : 2,
  Rubrics : 3,  
  Events : 4,
  Calendar : 5,
  Feedback : 6,
  Users : 7
  };


