import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index.js'
import globalstore from './store/store.js'
//import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$loginApi = process.env.VUE_APP_ROOT_API + "/api/Login/v1";
Vue.prototype.$restApi = process.env.VUE_APP_ROOT_API + "/api/Nastoyatel/v1";
//Vue.use(CKEditor);

const requestInterceptor = (request) => {
	request.withCredentials = true;
	return request;
};

axios.interceptors.request.use((request) => requestInterceptor(request));

(async () => {
  await globalstore.dispatch('checkToken', Vue.prototype.$loginApi + '/checkjwt/');

  router.beforeEach((to, from, next) => 
  {
    if (!Object.prototype.hasOwnProperty.call(to.meta, "access") || to.meta.access.includes("*")) 
    {
      next();
      return;
    }
  
    // если закрытый маршрут и пользователь не залогинен, то редирект на логин
    if (!globalstore.state.signedIn) 
    {
      // сохраним путь, куда шел пользователь, чтоб потом его перенаправить после логина
      globalstore.commit('redirectPathMutation', { name: to.name, params: to.params });
      next({ name: "login" });
      return;
    }
  
    // если у пользователя нет роли, то отправим на отказ.
    if (!to.meta.access.some(r=> globalstore.state.user.roles.includes(r))) {
      next({ name: "denied" });
      return;
    }

    // все в порядке с ролями, продолжим
    next();
  });
  
  new Vue({
    router:router,
    render: h => h(App),
    store: globalstore
  }).$mount('#app')
})();


