<template>
<div>

<div class="liketable" v-if="this.markupType == 1">
<div class="row">
  <div class="column1" v-if="this.$store.state.signedIn">
    <div><img src="../assets/ns_inside.jpg" border="0"/></div>
    <div style="padding-top:10px">Привет,<b> {{this.$store.state.login}}!</b>&nbsp;
    (<router-link :to="{name: 'login', params:{menuItem: 0}}" v-on:click.native="logoff" class="menu">Выйти</router-link>)</div>
    <UserMenu ref="usermenu" />
  </div>
  <div class="column2">&nbsp;</div> 
  <div class="column3">
  <router-view :key="$route.fullPath"/>
  </div>
</div>
</div>

<div class="liketable" v-if="this.markupType == 2">
<div class="rowfull">
<router-view :key="$route.fullPath"/>
</div>
</div>

</div>
</template>

<script>
import UserMenu from './UserMenu.vue'

export default {
  name: 'MasterComponent',

  props: {
   markupType: Number
  },

  components: {
  UserMenu
  },

  methods:{
    async logoff() {
      try
      {
        await this.$store.dispatch('logoff', this.$loginApi + '/logout/');
        return true;
      }
      catch(error)
      {
        console.log(error);
        return false;
      }
    },
  }
}
</script>

<style scoped>
.liketable {
height:100%;
min-width: 1200px;
}

.column1 {
  float: left;
  width: 180px;
  height: 1100px;
  background-color:#c1e0ff;
  padding: 10px; 
}
.column2 {
  float: left;
  width: 5px;
  height:  1100px;
  background-color:#0066ff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.column3 {
  float: left;
  height: 100%;
  min-width: 800px;
  background-color:#ffffff;
  padding: 10px;
}

.row{
height: 100%;
}

.row:after {
  content: "";
  display: block;
  clear: both;
}
</style>