<template>
<h1>Выберите, пожалуйста, доступный раздел. &#128512;</h1>
</template>

<script>
export default {
  name: 'EmptyComponent',
  
  props:{
    menuItem: Number,
    },

   mounted(){
    this.$store.commit('formUpdate', {
            menuItem: this.menuItem,
            markupType: 1
        });
    }
}
</script>